import React from 'react'
import Layout from '../components/Layout'

export default () => {
  return (
    <Layout title='404' url='/404' page='404'>
      <section className='four-oh-four'>
        <h1 className='ics__h0'>404</h1>
        <h2 className='ics__p'>Page not found</h2>
      </section>
    </Layout>
  )
}
